:root {
  /* Used for texts (+ footer background) */
  --color-granite: #252629;
  /* Used for backgrounds (+ footer text color) */
  --color-foam: #ffffff;
  /* Used for buttons, icons, etc. */
  --color-nemo: #ff4a11;
  /* active color for buttons, icons, etc. */
  --color-nemo-mid: #ff5c29;
  /* hover color for buttons, icons, etc. */
  --color-nemo-dark: #e5430f;
  /* Used for borders and some backgrounds */
  --color-water-10: #e4e8f0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
